import React from 'react';

/**
 * @description   Main Logo Component
 */
export default () => {
  return (
    <h1>
      Botmation
    </h1>
  );
}